<template>
  <div class="block">
    <section class="promo">
      <div class="wrapper">
        <div class="promo__wrap">
          <div class="promo__logos">
            <img
              src="@/assets/img/images/logo-1.svg"
              alt="Московский центр иновационных технологий в здравоохранении"
              width="288"
              height="60"
            />
            <img
              src="@/assets/img/images/logo-2.svg"
              alt="Правительство Москвы"
              width="138"
              height="54"
            />
            <img
              src="@/assets/img/images/logo-3.svg"
              alt="Департамент здравоохранения города Москвы"
              width="158"
              height="54"
            />
          </div>
          <h1>
            Москва готова увеличить число исследований на базе городских
            медицинских организаций
          </h1>
          <div class="promo__text">
            <p>
              Авторизуйтесь на платформе и получите все преимущества проведения
              клинических исследований в городских медицинских организациях!
            </p>
          </div>
          <div class="promo__foot">
            <router-link
              v-if="!$store.getters.isAuthorized"
              to="/login"
              class="btn promo__btn"
              >вход</router-link
            >
            <router-link v-else to="/applications/create" class="btn promo__btn"
              >подать заявку</router-link
            >
            <a
              target="_blank"
              href="/docs/ecosystem.pdf"
              class="btn promo__btn promo__btn--brochure"
            >
              Все о клинических исследованиях в Москве
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="slide">
      <div class="wrapper">
        <img
          src="@/assets/img/images/slide_wide.jpg"
          alt=""
          class="slide__img"
        />
      </div>
    </section>

    <section id="measures" class="advantages">
      <div class="wrapper">
        <div class="advantages__wrap">
          <h2 class="title-2">преимущества поддержки</h2>
          <ul class="advantages__list">
            <li class="advantages__item">
              <img
                src="@/assets/img/icons/prei_1.svg"
                alt="Максимальная скорость начала исследования"
                class="advantages__img"
              />
              <div class="advantages__text">
                Максимальная скорость начала исследования
              </div>
            </li>
            <li class="advantages__item">
              <img
                src="@/assets/img/icons/prei_2.svg"
                alt="Быстрота набора добровольцев"
                class="advantages__img"
              />
              <div class="advantages__text">Быстрота набора добровольцев</div>
            </li>
            <li class="advantages__item">
              <img
                src="@/assets/img/icons/prei_3.svg"
                alt="Организационная помощь"
                class="advantages__img"
              />
              <div class="advantages__text">Организационная помощь</div>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!--
    <section class="benefits">
      <div class="wrapper">
        <ul class="benefits__list">
          <li class="benefits__item">
            <figure class="benefits__icon">
              <img src="@/assets/img/icons/icon-1.svg" alt="Максимальная скорость начала исследования" width="80" height="80">
            </figure>
            <p>Максимальная скорость<br>начала исследования</p>
          </li>
          <li class="benefits__item">
            <figure class="benefits__icon">
              <img src="@/assets/img/icons/icon-2.svg" alt="Быстрота набора добровольцев" width="80" height="80">
            </figure>
            <p>Быстрота набора<br>добровольцев</p>
          </li>
          <li class="benefits__item">
            <figure class="benefits__icon">
              <img src="@/assets/img/icons/icon-3.svg" alt="Организационная помощь" width="80" height="81">
            </figure>
            <p>Организационная<br>помощь</p>
          </li>
        </ul>
      </div>
    </section>

    -->
    <section class="help">
      <div class="wrapper">
        <div class="help__wrap">
          <p class="title-2">оказываем помощь</p>
          <ul class="help__list">
            <li class="help__item">Подбор городской медицинской организации</li>
            <li class="help__item">Выбор профессиональной команды</li>
            <li class="help__item">Поиск добровольцев</li>
            <li class="help__item">Подбор городской лаборатории</li>
            <li class="help__item">Взаимодействие с этическими комитетами</li>
            <!--            <li class="help__item">Оснащение оборудованием</li>
            <li class="help__item">Организация тренингов</li>-->
            <li class="help__item">Юридическое сопровождение исследования</li>
          </ul>
          <div class="help__more">и многое другое.</div>
        </div>
      </div>
    </section>
  </div>

  <section class="order" id="about">
    <div class="wrapper">
      <p class="title-3">
        Хотите <span>получить доступ</span> к платформе поддержки клинических
        исследований, подайте заявку онлайн
      </p>
      <ol class="order__list">
        <li class="order__item">Авторизуйтесь на&nbsp;платформе</li>
        <li class="order__item">Выберите меры поддержки</li>
        <li class="order__item">Подайте заявку</li>
        <li class="order__item">Отслеживайте статус в&nbsp;личном кабинете</li>
      </ol>
      <div class="order__wrap">
        <blockquote class="order__quote">
          Прием заявок открыт в любое время
        </blockquote>
        <router-link
          v-if="!$store.getters.isAuthorized"
          to="/login"
          class="btn order__btn"
          >вход</router-link
        >
        <router-link v-else to="/applications/create" class="btn order__btn">
          подать заявку
        </router-link>

        <a
          href="/uploads/presentation.pdf"
          target="_blank"
          class="btn btn__auto order__presentation-btn"
          >Инструкция для участия</a
        >
        <a
          href="/uploads/instruction.pdf"
          target="_blank"
          class="btn btn__auto order__presentation-btn"
        >
          Инструкция для оформления заявки
        </a>
      </div>
    </div>
  </section>

  <section class="scheme">
    <div class="wrapper">
      <div class="scheme__wrap">
        <div class="scheme__title">
          <p class="title-2 title-2--sticky">cхема рассмотрения заявки</p>
        </div>
        <div class="scheme__column">
          <ul class="scheme__list">
            <li class="scheme__item">
              <div class="scheme__text">
                <p class="title-4">Подача заявки</p>
                <ul>
                  <li>
                    Материалы заявки не должны содержать сведений, составляющих
                    государственную, врачебную и иную охраняемую законом тайну,
                    а также конфиденциальную информацию служебного характера.
                    Заявки, документы и материалы, имеющие ограничительные
                    грифы, к рассмотрению не принимаются
                  </li>
                </ul>
              </div>
            </li>
            <li class="scheme__item">
              <div class="scheme__text">
                <p class="title-4">Рассмотрение заявки</p>
                <ul>
                  <li>
                    Рассмотрение заявок проводится по мере необходимости, но не
                    реже одного раза в месяц
                  </li>
                </ul>
              </div>
            </li>
            <li class="scheme__item">
              <div class="scheme__text">
                <p class="title-4">Уведомление о результатах</p>
              </div>
            </li>
            <li class="scheme__item">
              <div class="scheme__text">
                <p class="title-4">
                  Заключение соглашения об оказании поддержки клинического
                  исследования
                </p>
              </div>
            </li>
            <!--
            <li class="scheme__item">
              <div class="scheme__text">
                <p class="title-4">Уведомление о дате<br>рассмотрения заявки</p>
              </div>
            </li>
            <li class="scheme__item">
              <div class="scheme__text">
                <p class="title-4">Получение заключения<br>Клинического комитета</p>
                <p>Заключение выдается:</p>
                <ul>
                  <li>о соответствии (несоответствии) заявки установленным критериям отбора</li>
                  <li>об обоснованности видов организационной поддержки и статей (видов расходов)</li>
                </ul>
              </div>
            </li>
            <li class="scheme__item">
              <div class="scheme__text">
                <p class="title-4">В случае положительного решения Клинического комитета – издание приказа об оказании поддержки клинического исследования</p>
              </div>
            </li>
            <li class="scheme__item">
              <div class="scheme__text">
                <p class="title-4">Заключение соглашения<br>с получателем поддержки</p>
              </div>
            </li>
            -->
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="criteria">
    <div class="wrapper">
      <ul class="criteria__list">
        <li class="criteria__item" id="requirements">
          <p class="criteria__title criteria__title--top">
            Требования к заявителю
          </p>
          <div class="criteria__dropdown">
            <ol>
              <li>
                Обладает в соотвествии с Федеральным законом от 12 апреля 2010
                г. N 61-ФЗ «Об обращении лекарственных средств» правом на
                осуществление организации проведения клинических исследований
                лекарственных препаратов для медицинского применения
              </li>
              <li>
                Проводит (планирует проводить) клиническое исследование в
                городских медицинских организациях
              </li>
              <li>
                Получено разрешение на проведение клинического исследования
                лекарственного препарата для медицинского применения, выданное
                Министерством здравоохранения Российской Федерации (далее –
                клиническое исследование)
              </li>
            </ol>
          </div>
        </li>
        <li class="criteria__item" id="documents">
          <p class="criteria__title">Необходимые документы</p>
          <div v-show="false" class="criteria__dropdown">
            <p class="criteria__subtitle">Заявка</p>
            <p class="criteria__subtitle">Пакет документов:</p>
            <ol>
              <li>
                Учредительные документы организации (устав, свидетельство о
                государственной регистрации, свидетельство о постановке на учет
                в налоговом органе, решение и приказ о назначении на должность
                руководителя, доверенность на подписанта соглашения, если
                соглашение подписывается по доверенности не руководителем
                организации)
              </li>
              <li>
                Разрешение на проведение клинического исследования, выданное
                уполномоченным федеральным органом исполнительной власти (в
                случае направления заявки на возмещение части затрат, в
                разрешении должны быть указаны городские медицинское
                организации)
              </li>
              <li>
                В случае подачи заявки на организационные меры поддержки
                предоставляется Протокол клинического исследования. <br />
                В случае оказания финансовых мер поддержки предоставляется
                Протокол клинического исследования и брошюра исследователя.
              </li>
              <li>
                В случае подачи заявки на финансовую поддержку, необходимо
                представить смету всех расходов на проведение клинического
                исследования, которые возникли (могут возникнуть) в процессе
                проведения клинического исследования в городских медицинских за
                подписью руководителя организации.
              </li>
            </ol>
          </div>
        </li>
        <li class="criteria__item" id="criteria">
          <p class="criteria__title">Критерии отбора исследований</p>
          <div v-show="false" class="criteria__dropdown">
            <table class="criteria__table">
              <tr>
                <td>актуальность</td>
                <td>
                  Соответствие клинического исследования направлениям и задачам
                  в сфере лекарственного обеспечения
                </td>
              </tr>
              <!--              <tr>
                <td>новизна</td>
                <td>Наличие и доступность аналогичных лекарственных препаратов</td>
              </tr>-->
              <tr>
                <td>практическая реализуемость</td>
                <td>
                  Потенциал использования препарата на практике, в т.ч.
                  медико-экономическая эффективность
                </td>
              </tr>
              <tr>
                <td>значимость для развития здравоохранения г.Москвы</td>
                <td>
                  Общее значение препарата на развитие здравоохранения (в т.ч.
                  на показатели здоровья населения, показатели деятельности
                  системы здравоохранения)
                </td>
              </tr>
            </table>
          </div>
        </li>
        <li class="criteria__item">
          <p class="criteria__title">Перечень мер поддержки</p>
          <div v-show="false" class="criteria__dropdown">
            <div class="criteria__block">
              <p class="criteria__subtitle">Организационные меры поддержки</p>
              <ol>
                <li>
                  Помощь в подборе городских медицинских организаций для
                  проведения клинического исследования
                </li>
                <li>Помощь в выборе исследователей</li>
                <li>
                  Предварительная оценка выполнимости клинического исследования
                </li>
                <li>
                  Содействие в наборе здоровых добровольцев/пациентов для клинического исследования в городских медицинских организациях
                </li>
                <li>
                  Организация оснащения городских медицинских организаций
                  необходимым для проведения клинического исследования
                  оборудованием (в том числе вспомогательным), расходными
                  материалами
                </li>
                <li>
                  Взаимодействие с локальными этическими комитетами и Московским
                  городским независимым этическим комитетом в части проведения
                  клинических исследований в городских медицинских организациях
                </li>
                <li>
                  Оказание юридической помощи при заключении договоров
                  (соглашений) в рамках клинического исследования
                </li>
                <li>
                  Мониторинг готовности городских медицинских организаций для
                  проведения клинического исследования
                </li>
                <li>
                  Организация проведения тренингов исследовательских команд
                </li>
                <li>
                  Содействие в сопровождении клинического исследования (помощь в
                  подготовке плана-графика клинического исследования и помощь в
                  обеспечении его соблюдения)
                </li>
                <li>
                  Помощь в подборе лаборатории для выполнения клинических
                  лабораторных исследований в медицинских организациях,
                  подведомственных Департаменту здравоохранения города Москвы
                  (далее – городские лаборатории)
                </li>
                <li>
                  Содействие в проведении клинического исследования с
                  использованием функциональных возможностей информационных
                  систем города Москвы
                </li>
                <li>
                  Организация хранения документов клинического исследования в
                  отношении городских медицинских организаций
                </li>
              </ol>
            </div>
            <div class="criteria__block">
              <p class="criteria__subtitle">Возмещение части затрат</p>
              <p class="criteria__text">
                Перечень затрат организации, которые возникли (могут возникнуть)
                в процессе проведения клинического исследования в городских
                медицинских организациях, требующих возмещения (но не более 50%
                общей суммы расходов на проведение клинического исследования)
              </p>
              <ol>
                <li>
                  Расходы, связанные с адаптацией городской медицинской
                  организации для проведения клинического исследования
                </li>
                <li>
                  Затраты на расходные материалы, приобретение оборудования и
                  (или) комплектующих к нему, приобретение или аренда
                  вспомогательного оборудования, необходимых при проведении
                  клинического исследования в городских медицинских организациях
                </li>
                <li>
                  Расходы на печать и доставку полиграфической продукции,
                  необходимой при проведении клинического исследования в
                  городских медицинских организациях
                </li>
                <li>
                  Расходы на выполнение городскими лабораториями клинических
                  лабораторных исследований в рамках проведения скрининга и
                  (или) в процессе проведения клинического исследования в
                  отношении здоровых добровольцев/пациентов (согласно перечню
                  исследований, выполняемых лабораториями).
                </li>
                <li>
                  Расходы на транспортировку биоматериала в городские
                  лаборатории из городских медицинских организаций
                </li>
                <li>
                  Расходы на выполнение инструментальных исследований пациентов
                  в период проведения скрининга и в процессе проведения
                  клинического исследования в отношении здоровых
                  добровольцев/пациентов (с учетом возможностей городских
                  медицинских организаций) в городских медицинских организациях.
                </li>
                <li>
                  Расходы на транспортировку лекарственных препаратов для
                  проведения клинического исследования в городские медицинские
                  организации
                </li>
                <li>
                  Расходы на фонд оплаты труда членов исследовательских команд
                  городских медицинских организаций (за исключение выплат
                  главным исследователям)
                </li>
                <li>
                  Расходы на оплату услуг этических комитетов по организации
                  экспертизы и оформлению заключений документов клинического
                  исследования, проводимого в городских медицинских организациях
                </li>
              </ol>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <div class="reg" v-if="!$store.getters.isAuthorized">
    <div class="wrapper">
      <div class="reg__wrap">
        <p class="title-2">Авторизуйтесь для подачи заявки</p>
        <a @click.prevent="$router.push('/login')" href="#" class="btn reg__btn"
          >Авторизоваться</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  mounted() {
    const $ = window.jQuery;
    $('.criteria__title').click(function () {
      $(this).next().slideToggle();
      $(this).toggleClass('criteria__title--top');
    });
    $.fn.isInViewport = function () {
      let elementTop = $(this).offset().top;
      let elementBottom = elementTop + $(this).outerHeight();
      let viewportTop = $(window).scrollTop();
      let viewportBottom = viewportTop + $(window).height();
      return (
        elementBottom > viewportTop + $(window).height() / 4 &&
        elementTop < viewportBottom
      );
    };

    const $schemeItems = $('.scheme__list .scheme__item');
    $(window).on('resize scroll', function () {
      let $firstVisible = false;
      $schemeItems.each(function () {
        if (!$firstVisible && $(this).isInViewport()) {
          $(this).addClass('scheme__item--visible');
          $firstVisible = $(this);
        } else {
          $(this).removeClass('scheme__item--visible');
        }
      });
    });
  },
};
</script>
